<template>
  <div class="home">
    <div class="page-nav">
      <router-link to="/home/disease">艾灸</router-link>
      <router-link to="/home/acupoint">穴位</router-link>
      <router-link to="/home/graphic">外治图解</router-link>
      <router-link to="/home/herb">中药</router-link>
      <router-link to="/home/symptom">针灸</router-link>
      <router-link to="/exam">体测</router-link>
    </div>
    <div class="home-panel">
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeIndex',
  components: {
  },
  data: function() {
    return {
    }
  }
}
</script>