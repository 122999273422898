import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


import videoPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'

const app = createApp(App);

app.component('Vue3VideoPlay', videoPlay);
app.use(router);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css';

app.use(VueAwesomeSwiper);

// 定义全局异常处理函数
app.config.errorHandler = (err, instance, info) => {
    console.error('Unhandled error:', err);
    console.error('instance:', instance);
    console.error('info:', info);
    // 这里可以添加额外的逻辑，例如发送错误报告到服务器
    // 可以使用 `instance` 访问 Vue 实例，`info` 是错误信息的字符串描述
};

app.mount('#app');